<template>
  <v-footer id="home-footer" color="grey darken-4" dark min-height="72">
    <v-container>
      <v-row>
        <v-col class="d-flex justify-center">
          Rua García Prieto 64. Santiago de Compostela. CP: 15706
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex justify-center text-center text-md-right"
          cols="12"
          md="12"
        >
          Copyright &copy; 2020 SolucionesMerlin
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({})
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
